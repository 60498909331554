@import 'colors.css';

/* Tablets and bigger are constrained to a max width and are centered */
@media (min-width: 767px) {
  html {
    background: var(--base2);
  }

  #root {
    box-shadow: 0 2em 2em 1.5em var(--base1-soft);
  }

  #root,
  .action-drawer-container,
  .drawer-inner-container,
  .loading-indicator {
    max-width: 35em;
    margin-left: auto;
    margin-right: auto;
  }

  .action-drawer-container {
    max-width: 33em;
  }
}

body,
.App {
  height: 100%;
}

html,
body,
#root {
  color: var(--base00);
  background: var(--base3);
  user-select: none;
}

a {
  color: var(--base03);
}

h2 {
  color: var(--base01) !important;
}

.btn {
  cursor: pointer;
  background-color: var(--magenta);
  color: var(--base3) !important;

  border: 0;
  padding: 8px 16px;
}

.btn a {
  color: var(--base3) !important;
}

.btn--circle {
  border-radius: 50%;
  font-weight: bold;
  padding: 0;
  width: 60px;
  height: 60px;
}

.btn--disabled {
  background-color: var(--base01);
}

.nice-scroll {
  -webkit-overflow-scrolling: touch;
}

.textarea {
  width: 95%;
  font-size: 16px;
  font-family: Courier;

  -webkit-appearance: none;
  border-radius: 0;
}

.textfield {
  border: 1px solid var(--base1);
  border-radius: 0;
  -webkit-appearance: none;
  padding: 5px;
  font-size: 16px;
}

.label-for-checkbox {
  margin: 4px 0 0;
}

div[tabindex='-1']:focus {
  outline: 0;
}

button {
  touch-action: manipulation;
}

.checkbox {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;

  border: 2px solid var(--green);
  border-radius: 2px;
  box-sizing: border-box;

  display: inline-block;

  color: var(--base00);
}
