.entry-container {
  font-size: 16px;

  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
}

.entry-container--large-font {
  font-size: 20px;
}
